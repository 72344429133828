import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import CustomImage from "./CustomImage"

const PageTitle = ({ title, intro, ...props}) => {
  // const data = useStaticQuery(
  //   graphql`
  //     query PageTitleQuery {
  //       info(fileID: {eq: "infos-restaurant"}) {
  //         fileID
  //         heroIMG {
  //           publicURL
  //           childImageSharp {
  //             gatsbyImageData(quality: 60)
  //           }
  //         }
  //       }
  //     }
  //   `
  // )
  // const content = data.info
  const data = useStaticQuery(
    graphql`
      query PageTitleQuery {
        contentJson(fileID: {eq: "infos"}) {
          fileID
          heroIMG {
            publicURL
            childImageSharp {
              gatsbyImageData(quality: 60)
            }
          }
        }
      }
    `
  )
  const content = data.contentJson
  return (
    <div className="p-4 pt-0" {...props}>
      <div className="relative">
        <CustomImage
          img={content.heroIMG}
          layout="fullWidth"
          alt="Hero header background"
          className="inset-0 h-full z-10"
          style={{
            position: 'absolute'
          }}
        />
        <div className="relative z-20 py-8 sm:py-12 md:py-24 container mx-auto text-center">
          <h1 className="uppercase">{title}</h1>
          {intro && (
            <p className="mt-4">{intro}</p>
          )}
        </div>
      </div>
    </div>
  )
};

export default PageTitle